










































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'

export default Vue.extend({
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            data: [],
            // tab: 0,
        }
    },
    mounted() {
        this.onInit()
    },
    methods: {
        // changeTab(tab) {
        //     this.tab = tab
        //     this.onInit()
        // },
        onInit() {
            this.pageNum = 1
            this.getData()
        },
        onLoad() {
            this.pageNum += 1
            this.getData()
        },
        getData() {
            this.loading = true
            const { pageNum, pageSize } = this
            const params = { pageNum, pageSize }
            request.get('/api/tobacco/rest_field_class/page', params).then(({list, total}) => {
                if(pageNum==1) this.data = list
                else this.data = this.data.concat(list)
                this.total = total
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        view(url: string) {
          const type = url.split('.')[1]
            let baseUrl = ''
            if (process.env.NODE_ENV=='development') {
                baseUrl = 'https://ylwx.sxktqx.com'
            }
            // window.location.href = `${baseUrl}/docs_static${url}`
            // this.$router.push({
            //     path: '/file_view',
            //     query: {url: `/files${url}`},
            // })

            this.$router.push({
                path: '/doc_view',
                query: {url: `https://ylwx.sxktqx.com/files${url}`, type},
            })
          // window.location.href = `http://ylwx.sxktqx.com/files${url}`
        }
    },
    filters: {
        dtFormat(timestamp: number) {
            const time = moment(timestamp)
            return time.format('YYYY/MM/DD')
        }
    }
})
